import React, { useCallback } from 'react';
import useOnReveal from '@hooks/use-on-reveal';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import MerchandiseImage from '@components/molecules/merchandise-block/components/merchandise-image';
import { MerchandiseBlock as IMerchandiseBlock } from '@interfaces/models/merchandise';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { CMSSimpleTextFieldType } from '@interfaces/models/cms/campaign-hero';
import { SimpleTextField } from '../cms/cms-block-fields/components';
import styles from './merchandise-block.module.scss';

const MerchandiseBlock: React.FC<IMerchandiseBlock> = ({
  displayTitle: title,
  url,
  image,
  contentTypeUid: contentTypeUID,
  title: label,
  idx,
  sectionIdx,
  blockLength,
  category,
  uid: merchandiseBlockId,
  displayStyle,
  imagesAspectRatio,
  itemTitleTextStyle,
  itemSubtitleTextStyle,
  subtitle,
}): React.JSX.Element => {
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { setHpScrollPosition } = useRestoreHpScrollPosition();
  const blockId = `HP_${String(merchandiseBlockId)}`;
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        label: `${String(idx + 1)}_${sectionIdx}`,
        property: `${merchandiseBlockId}_${label}`,
      });
    }, [contentTypeUID, category, label, idx, merchandiseBlockId]),
    0.5,
    false,
  );

  if (!url) {
    return null;
  }

  const trackMerchandiseBlockClick = (e) => {
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: contentTypeUID,
      label: `${String(idx + 1)}_${sectionIdx}`,
      property: `${merchandiseBlockId}_${label}`,
    });

    e.stopPropagation();
    setHpScrollPosition(blockId);
  };

  return (
    <div
      ref={containerRef}
      className={styles[`merchandising-${blockLength}`]}
      id={blockId}
    >
      <a
        className={styles.merchandising__a}
        href={url}
        onClick={trackMerchandiseBlockClick}
        target={'_self'}
        itemProp="url"
      >
        <MerchandiseImage
          image={image}
          blockLength={blockLength}
          displayStyle={displayStyle}
          imagesAspectRatio={imagesAspectRatio}
        />
        {itemTitleTextStyle ? (
          <SimpleTextField
            data={{
              simpleTextFieldType: CMSSimpleTextFieldType.Title,
              fieldStyle: itemTitleTextStyle,
              text: title,
            }}
            fieldType={CMSSimpleTextFieldType.Title}
            blockStyle="merch"
          ></SimpleTextField>
        ) : (
          <p className={styles.merchandising__p}>{title}</p>
        )}

        {subtitle &&
          (itemSubtitleTextStyle ? (
            <SimpleTextField
              data={{
                simpleTextFieldType: CMSSimpleTextFieldType.Subtitle,
                fieldStyle: itemSubtitleTextStyle,
                text: subtitle,
              }}
              fieldType={CMSSimpleTextFieldType.Subtitle}
              blockStyle="merch"
            ></SimpleTextField>
          ) : (
            <p className={styles.merchandising__p}>{subtitle}</p>
          ))}
      </a>
    </div>
  );
};

export default MerchandiseBlock;
