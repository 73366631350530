import React, { useState } from 'react';
import { HeaderDataItem } from '@interfaces/models/header';
import Tracer from '@components/atoms/tracer/tracer';
import DesktopItem from '@components/molecules/main-nav/components/desktop-item';
import useWindowSize from '@hooks/use-window-size';
import useDirectShippingEligibilityQuery from '@hooks/header/use-direct-shipping-eligibility-query';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import dynamic from 'next/dynamic';
import { useBucket } from '@context/bucket.context';

import styles from './main-navigation.module.scss';

const MobileItem = dynamic(() => import('@components/molecules/main-nav/components/mobile-item'), {
  ssr: false,
});

interface MainNavigationProps {
  headerContent: HeaderDataItem[];
}

const MainNavigation: React.FC<MainNavigationProps> = ({ headerContent }) => {
  const { isUserDirectShippingEligible } = useDirectShippingEligibilityQuery();
  const { isWiderThanLg } = useWindowSize();
  const { sendAnalyticEvent, updateDataLayer } = useAnalyticEvents('navigation');
  const [selectedItemId, setSelectedItemId] = useState<number>(null);
  const [isMainNavigationOpen, setIsMainNavigationOpen] = useState<boolean>(false);
  const { getFeature } = useBucket();
  const overrideEntries = getFeature('browsingMenuEntries', true)?.value?.overrideEntries || {};

  // Some links require additional checks, so we put those in the filter
  const displayedLinks: HeaderDataItem[] = headerContent.filter((link: HeaderDataItem): boolean => {
    if (overrideEntries[link.mnemonic]) {
      const newLink = overrideEntries[link.mnemonic]?.link || link.url.seo;
      link.url = {
        ...link.url,
        seo: newLink,
      };
    }

    // Display direct shipping only if customer is eligible for it
    if (link.mnemonic === 'direct-shipping') {
      return isUserDirectShippingEligible;
    }
    return true;
  });

  const onClick = (label: string) => {
    let parsedLabel = label;
    // if last character is a slash, remove it
    if (parsedLabel.slice(-1) === '/') {
      parsedLabel = parsedLabel.slice(0, -1);
    }
    // remove spaces at the start and end of the string
    parsedLabel = parsedLabel.trim();
    sendAnalyticEvent('click_category_menu', { label: parsedLabel });
    updateDataLayer({
      source_category: 'category_menu',
      source_subcategory: parsedLabel,
    });
  };

  const select = (id: number | null): void => {
    setSelectedItemId(id);
  };

  return (
    <>
      {/* Desktop */}
      {isWiderThanLg && (
        <Tracer
          as="nav"
          selector={(container) => container.querySelectorAll(`.${styles.desktop} > ul > li`)}
          className={styles.desktop}
        >
          <ul onMouseLeave={() => setIsMainNavigationOpen(false)}>
            {displayedLinks.map((link: HeaderDataItem) => (
              <DesktopItem
                isMainNavigationOpen={isMainNavigationOpen}
                setIsMainNavigationOpen={setIsMainNavigationOpen}
                headerDataItem={link}
                show={selectedItemId === link.id}
                onSelect={select}
                key={link.id}
                onClick={onClick}
              />
            ))}
          </ul>
        </Tracer>
      )}
      {/* Mobile & tablet */}
      {!isWiderThanLg && (
        <nav className={styles.mobile}>
          <ul className={styles.mobile__topLevelMenu}>
            {displayedLinks.map((child: HeaderDataItem) => (
              <MobileItem
                headerDataItem={child}
                key={child.id}
                onClick={onClick}
              />
            ))}
          </ul>
        </nav>
      )}
    </>
  );
};

export default MainNavigation;
