import React, { useCallback } from 'react';
import useOnReveal from '@hooks/use-on-reveal';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import { CampaignBlock as ICampaignBlock, CategoryContainer } from '@interfaces/models/campaign';
import CampaignImg from '@components/molecules/campaign-block/components/campaign-img';
import CampaignCTA from '@components/molecules/campaign-block/components/campaign-cta';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from './campaign-block.module.scss';

interface CampaignBlockProps extends ICampaignBlock {
  posX?: string;
  sectionIdx?: string;
  categoryContainer?: CategoryContainer;
}

const CampaignBlock: React.FC<CampaignBlockProps> = ({
  url,
  image,
  target,
  displayTitle: title,
  subtitle: subTitle,
  title: label,
  contentTypeUid: contentTypeUID,
  posX,
  sectionIdx,
  uid: campaignBlockId,
  category,
  ctaText,
  ctaPosition,
  ctaTextColor,
  ctaBackgroundColor: ctaBGColor,
  isHeroVersion,
  isSingle,
  categoryContainer,
}): React.JSX.Element => {
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { setHpScrollPosition } = useRestoreHpScrollPosition();
  const campaignBlockTrackingLabel = `${posX}_${sectionIdx ?? ''}`;
  const blockId = `HP_${String(campaignBlockId)}`;
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        label: campaignBlockTrackingLabel,
        property: `${campaignBlockId}_${label}`,
      });
    }, [contentTypeUID, category?.category, campaignBlockId, label, posX]),
    0.5,
    false,
  );

  const sendAnalyticEventWithObject = (eventDataObject) => {
    sendAnalyticEvent('cms_content_click', eventDataObject);
  };
  const trackCampaignBlockClick = (e) => {
    const eventData = {
      category: 'cms_content_click',
      action: contentTypeUID,
      label: campaignBlockTrackingLabel,
      property: `${campaignBlockId}_${label}`,
    };
    sendAnalyticEventWithObject(eventData);
    setHpScrollPosition(blockId);
    e.stopPropagation();
  };

  const onClick = (e, chip, chipXPos) => {
    const eventData = {
      category: 'cms_content_click',
      action: chip?.contentTypeUid,
      label: `${String(chipXPos + 1)}_${sectionIdx}`,
      property: `${chip?.uid}_${chip?.title}`,
    };
    sendAnalyticEventWithObject(eventData);
    e.stopPropagation();
    setHpScrollPosition(blockId);
  };

  const campaignCTAProps = {
    ctaPosition,
    ctaBGColor,
    ctaTextColor,
    title,
    subTitle,
    ctaText,
    isHeroVersion,
    isSingle,
    categoryContainer,
    sectionIdx,
  };

  return (
    <div
      ref={containerRef}
      className={styles.campaign__container}
      id={`HP_${String(campaignBlockId)}`}
    >
      <a
        className={styles.campaign__a}
        href={url}
        onClick={trackCampaignBlockClick}
        target={target || '_self'}
        itemProp="url"
      >
        <CampaignImg image={image} />
        <CampaignCTA
          onClick={onClick}
          {...campaignCTAProps}
        />
      </a>
    </div>
  );
};

export default CampaignBlock;
